export const datacart1={

    titulo2:"select number of hours for the service",
    img1:require('../../public1/images/join.svg').default,
    defaultnumber:1,
    
}
export const datacart2={
    
    titulo2:"select number of pets yo have in your place",
    img1:require('../../public1/images/cat1.svg').default,
    defaultnumber:0,
    
}

export const datacart3={
    
    titulo2:"select an starting hour for the service",
    img1:require('../../public1/images/time.svg').default,
    defaultnumber:0,
    
}
export const datacart4={
    
    titulo2:"select a date for th service",
    img1:require('../../public1/images/calendar1.svg').default,
    defaultnumber:0,
    
}

export const datacart5={
    
    // titulo2:"select a date for th service",
    // img1:require('../../public1/images/calendar1.svg').default,
    defaultnumber:0,
    
}