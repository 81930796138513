import styled  from "styled-components";
import {Link as LinkRouter} from 'react-router-dom';

export const InfoContainer=styled.div`
color:#fff;
background: ${({LightBg})=>(LightBg ? '#f9f9f9':'#010606')};
@media screen and (max-width: 768px){
    padding:35px 0;
}
`

export const InfoWrapper= styled.div`
display:grid;
z-index:1;
height:900px
width:100%
max-width:1100px;
margin-right:auto;
margin-left:auto;
padding:24px 24px;
justify-content:center




`;


export const InfoRow= styled.div`
display:grid;
grid-auto-columns:minmax(auto,1fr);
align-items:center;
grid-template-areas:${({imgStart})=>(imgStart ? `'col2 col1'`:`'col1 col2'`)};
@media screen and (max-width:768px){
grid-template-areas:${({imgStart})=>(imgStart ? `'col1''col2'`:`'col1 col1' 'col2 col2'`)}


}

`;
export const Column1= styled.div`
margin-botton:15px;
padding:0 15px;
grid-area: col1;

`;

export const Column2= styled.div`
margin-top:15px;
margin-botton:15px;
padding:0 15px;
grid-area: col2;


`;

export const TextWrapper= styled.div`
margin-width:540px;
padding-top:0;
padding-botton:60px;
`;
export const TopLine= styled.p`
color:#01BF71;
font-size:16px;
line-height:16px;
font-weight:700;
letter-spacing: 1.4px;
text-transform:uppercase;
margin-bottom:20px;
`;
export const Heading= styled.h1`
margin-bottom:28px;
font-size:48px
line-height:1.1;
font-weight:600;
color:${({LightText})=>(LightText ? '#f7f8fa':'#010606')};
@media screen and(max-width:480px){
    font-size:32px
}
`;
export const Subtitle= styled.p`
max-width:440px;
color:${({darkText})=>(darkText ? '#010606':'#fff')};
font-size:18px;
line-height:24px;
margin-bottom:40px;
`;

export const BtnWrap=styled.div`
display:flex;
justify-content:space-between;
`;
export const ImgWrap=styled.div`
max-width=355px;
height:95%;


`;
export const Img=styled.img`
width:100%;
margin:0 0 10px 0;

padding-right:0;

`;

export const MobileIconSms= styled.div`
margin-left:15px

  display:flex;
  justify-content:flex-start;
    tranform: translate(-100%,60%);
    font-size: 1.8rem;
    cursor:pointer;
    text-decoration:none
    color:#fff
    

`;
export const MobileIconemail= styled.div`
margin-left:15px

  display:flex;
  justify-content:flex-start;
    tranform: translate(-100%,60%);
    font-size: 1.8rem;
    cursor:pointer;
    text-decoration:none
    color:#fff
    

`;
